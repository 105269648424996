import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

// Components
import Layout from '../../../components/layout/Layout';
import RealisationServiceCard from '../../../components/card/RealisationService';
import PaginationBlock from '../../../components/block/Pagination';

// Services 
const RealisationsServicesPage = ({ pageContext, data }) => {
  
  const {t} = useTranslation();

  const { languagePath } = pageContext;
  const totalCount = data.services.group.length;

  const pageSchemas = [
    { 
      type: "breadcrumb",
      listItems: [
        { 
          name: t('breadcrumb.realisations'),
          item: languagePath + "realisations/"
        },
        { 
          name: t('breadcrumb.services'),
        },
      ]
    }
  ];

  return (
    <Layout pageType="classic" 
      pageTitle={t('meta.title')} 
      pageDescription={t('meta.description', { title: data.site.siteMetadata.title })} 
      pageKeywords={t('meta.keywords', { title: data.site.siteMetadata.title })} 
      pageSchemas={pageSchemas}
      headerClass="navbar-absolute-top navbar-light navbar-show-hide" 
      headerStyle="light"
    >
      <div className="container content-space-t-3 content-space-t-lg-3 content-space-b-1 content-space-b-md-2">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to={`/`}>{t('breadcrumb.home')}</Link></li>
            <li className="breadcrumb-item"><Link to={`/realisations/`}>{t('breadcrumb.realisations')}</Link></li>
            <li className="breadcrumb-item active">{t('breadcrumb.services')}</li>
          </ol>
        </nav>
        <div className="w-md-75 w-lg-50 text-center mx-md-auto">
          <h1 className="display-5">{t('title', { title: data.site.siteMetadata.title, total_count: totalCount })}</h1>
          <p className="lead">{t('subtitle', { title: data.site.siteMetadata.title, total_count: totalCount })}</p>
        </div>
      </div>
      <div className="container content-space-b-2 content-space-b-lg-3">
        <div className="ps-lg-2">
          <div className="row gx-3 mb-7">
            {data.services.group &&
              data.services.group.map((service, index) => (
                <RealisationServiceCard key={index} data={service} cardStyle="1" cardClassName="col-md-6 col-lg-4 mb-3"/>
              ))
            }
          </div>              
        </div>
        <div>
          <PaginationBlock 
            path={`realisations/services`}
            currentPage={pageContext.currentPage} 
            numPages={pageContext.numPages} 
          />
        </div>
      </div>
    </Layout>
  )
}

RealisationsServicesPage.propTypes = {
  data: PropTypes.shape({
    services: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
}

export default RealisationsServicesPage

export const query = graphql`
  query ($language: String!, $skip: Int!, $limit: Int!) {
    site: site {
      siteMetadata {
        siteUrl
        title
      }
    }
    services: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/realisations/" },
        frontmatter: { active: { eq: true }, language: {eq: $language} }
      },
      sort: {fields: frontmatter___date, order: DESC}
      limit: $limit
      skip: $skip    
    ) {
      group(field: frontmatter___services, limit: 1) {
        title: fieldValue
        totalCount
        nodes {
          frontmatter {
            meta {
              title
              description
              keywords
            }
            title
            category
            tags
            services
            languages
            description
            client
            partners
            services
            image {
              card {
                childImageSharp {
                  gatsbyImageData(width: 800)
                }
              }
              alt
            }
            home
            active
            language
            path
            date(formatString: "MMMM YYYY", locale: $language)
          }
          id
          timeToRead
        }
      }
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "breadcrumb", "realisations-services", "categories", "tags", "services", "pagination", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`