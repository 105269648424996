import * as React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link, useTranslation} from 'gatsby-plugin-react-i18next';
import slugify from '@sindresorhus/slugify';

const RealisationServiceCard = ({ data, cardStyle, cardClassName }) => {
  
  const {t} = useTranslation();

  return (
    <div key={data.id} className={cardClassName} data-aos="fade-up">
      <Link to={`/realisations/services/${slugify(data.title)}/`}>
        <div className="card card-bordered card-transition shadow">
          <div key={data.id} className="mb-4">
            {data.nodes ? 
              data.nodes.map((realisation, index) => (
                <GatsbyImage
                  key={index}
                  image={getImage(realisation.frontmatter.image.card)}
                  className="card-img-top card-img-h-10"
                  alt={realisation.frontmatter.image.alt}
                />                    
              ))
            : null }
          </div>
          <div key={data.id} className="card-body">
            <p className="h4 text-dark">{t('realisation.services.' + data.title + '.label')}</p>
            <p className="text-muted">{t('totalCount', { count: data.totalCount })}</p>
          </div>
        </div>
      </Link>
     </div>
  )
}

export default RealisationServiceCard